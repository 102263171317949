/* FileUpload.css */
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%;
    max-height: 100%;
  }
  