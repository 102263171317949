.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5rem;
  vertical-align: middle;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  transition: transform 0.3s ease-in-out;
}

.caret-up {
  border-top: 0.3em solid currentColor; 
}

.caret-down {
  transform: rotate(180deg); 
  border-top: 0.3em solid currentColor; 
}

.accordion-title {
  color: #374151;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.accordion-content {
  overflow: scroll;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.accordion-content.open {
  max-height: 100vh; 
}

.accordion-wrapper {
  border-top: 1px solid #D1D5DB;  
  border-bottom: 1px solid #D1D5DB; 
}
