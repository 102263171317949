.loading-dots {
    display: inline-block;
    font-size: 1.25em;
    position: relative;
  }
  
  .loading-dots > span {
    animation: loadingDots 1s infinite;
    opacity: 0;
  }
  
  .loading-dots > span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loading-dots > span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loading-dots > span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loadingDots {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .message.animate {
    overflow: hidden;
    white-space: nowrap;
    animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  

  
  
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: black;
    }
  }
  